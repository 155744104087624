import React, { useState } from "react"
import { graphql } from "gatsby"
import ListingPageGridLayout from "../layouts/ListingPageGridLayout"
import topTagsFilter from "../utils/topTagsFilter"
function ResourcesPage({ data }) {
  const blockedDomain = "www.guru99.com"

  const articles = data.TopTags.nodes.filter(
    (article) => article.domain_name !== blockedDomain
  )

  const [topTagsWithRecentPosts, sortedTags] = topTagsFilter({
    data: data.TopTags.nodes,
    type: "Articles",
  })
  const [selectedTag, setSelectedTag] = useState(
    topTagsWithRecentPosts &&
      topTagsWithRecentPosts.length > 0 &&
      topTagsWithRecentPosts[0]
      ? topTagsWithRecentPosts[0].tag
      : "cassandra"
  )
  const listingItems = articles.filter((item) =>
    item.tags.includes(selectedTag)
  )
  return (
    <ListingPageGridLayout
      args={{
        articles,
        listingItems,
        topTagsWithRecentPosts,
        selectedTag,
        setSelectedTag,
        sortedTags,
      }}
    />
  )
}

export default ResourcesPage

export const pageQuery = graphql`
  query ListingArticles {
    TopTags: allCassandraLinks(
      filter: { content: { ne: null } }
      sort: { fields: [wallabag_created_at], order: [DESC, DESC] }
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        id
        wallabag_created_at
        published_at
        published_by
        language
        reading_time
        domain_name
        preview_picture
        content
        description
        tags
      }
    }
  }
`
