import React from "react"
import { Grid, Container } from "@mui/material"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import ListingArticleHeroSection from "../components/ArticleListingPage/ListingArticlesHeroSection"
import NewsLetterCard from "../components/ArticleSinglePage/Cards/NewsLetterCard"
import TrainingAdComponent from "../components/ArticleSinglePage/Cards/TrainingAdComponent"
import ExploreFurther from "../layouts/ExploreFurtherLayout"
import ArticlesFilters from "../components/ArticleListingPage/ArticlesFilters"
import ListArticlesLayout from "../layouts/ListArticlesLayout"
import ListingRelatedArticlesLayout from "./ListingRelatedArticlesLayout"
import { Helmet } from "react-helmet"
function ListingPageGridLayout({
  args: { articles, listingItems, selectedTag, setSelectedTag, sortedTags },
}) {
  return (
    <Layout>
      <Helmet>
        <title>Cassandra Link - Articles</title>
        <meta
          name="description"
          content="Explore informative articles and tutorials on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - Articles" />
        <meta
          property="og:description"
          content="Explore informative articles and tutorials on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/" />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - Articles" />
        <meta
          name="twitter:description"
          content="Explore informative articles and tutorials on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Articles - Cassandra Link",
            keywords: "Articles, Cassandra Link",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>

      <Grid container>
        <Grid item xs={12}>
          <ListingArticleHeroSection
            title={"articles"}
            data={articles.slice(0, 3)}
          />
        </Grid>
        <Container sx={{ marginTop: { xs: 6, sm: 0 } }} maxWidth="xl">
          <Grid sx={{ marginY: 5 }} item xs={12}>
            <ArticlesFilters
              args={{
                fileItems: sortedTags,
                selectedTag: selectedTag,
                setSelectedTag: setSelectedTag,
              }}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <ListArticlesLayout data={listingItems} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TrainingAdComponent buttonLink={"/training-cassandra"} />
                </Grid>
                <Grid item xs={12}>
                  <ListingRelatedArticlesLayout data={articles.slice(7, 10)} />
                </Grid>
                <Grid item xs={12}>
                  <NewsLetterCard />
                </Grid>
                <Grid item xs={12}>
                  <ListingRelatedArticlesLayout data={articles.slice(10, 13)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ marginY: 3 }} item xs={12}>
            <ExploreFurther
              args={{
                data: articles.slice(0, 12),
                isListingPage: true,
              }}
            />
          </Grid>
        </Container>
      </Grid>
    </Layout>
  )
}

export default ListingPageGridLayout
